import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import "./moreinfo.css";

const More_info = ({ cont }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="neInfo">
            <Collapse in={open}>
                <div id="collapse-text">
                    <div>
                        <h6>Oradores</h6>
                        <ul>
                            {cont.data.map((item, index) => (
                                <li key={index}>
                                    {item.name}
                                    <br/><span style={{color:"#b4b4b4"}}>{item.position}</span>
                                </li>
                            ))}

                        </ul>

                    </div>
                    {/*<div dangerouslySetInnerHTML={{__html: cont}} />*/}
                </div>
            </Collapse>
            <div
                onClick={() => setOpen(!open)}
                aria-controls="collapse-text"
                aria-expanded={open}
                 className="moreinfo-btn"
            >
                <span className="vermastext">Más info</span>
                <span className="arrowupdown"></span>
            </div>

        </div>
    );

}
export default More_info;